import { Link } from 'react-router-dom'
import { useState } from 'react'
import { useLogin } from '../../hooks/useLogin'
import NeuromodecLogo from '../../assets/logo.svg'
import firebase from 'firebase/app'
import 'firebase/auth'

// styles
import './Login.css'

export default function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { login, error, isPending } = useLogin()
  const [resetMessage, setResetMessage] = useState('')
  const [isResetMode, setIsResetMode] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    login(email, password)
  }

  const handleResetSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setResetMessage('Please enter your email address.');
      return;
    }
    
    // Define action code settings with your desired redirect URL
    const actionCodeSettings = {
      // URL to which the user will be redirected after completing the password reset.
      // Make sure this URL is whitelisted in your Firebase project's authentication settings.
      url: 'https://connect.neuromodec.org/',
      // Whether to handle the reset in the app.
      handleCodeInApp: false,
    };
  
    try {
      await firebase.auth().sendPasswordResetEmail(email, actionCodeSettings);
      setResetMessage('Password reset email sent. After resetting, you will be redirected to the website.');
    } catch (err) {
      setResetMessage(err.message);
    }
  };
  

  return (
    <div className="login-bg-container">
      {isResetMode ? (
        <form onSubmit={handleResetSubmit} className="auth-form">
          <div className="logo">
            <img src={NeuromodecLogo} alt="Neuromodec logo" />
          </div>
          <h2>Reset Password</h2>
          <label>
            <span>Email</span>
            <input
              required
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </label>
          <button className="btn" type="submit">Reset Password</button>
          {resetMessage && <div className="reset-message">{resetMessage}</div>}
          <p onClick={() => { setIsResetMode(false); setResetMessage(''); }} className="toggle-mode">
            Back to Login
          </p>
        </form>
      ) : (
        <form onSubmit={handleSubmit} className="auth-form">
          <div className="logo">
            <img src={NeuromodecLogo} alt="Neuromodec logo" />
          </div>
          <h2>Login</h2>
          <label>
            <span>Email</span>
            <input
              required
              type="email" 
              onChange={(e) => setEmail(e.target.value)} 
              value={email} 
            />
          </label>
          <label>
            <span>Password:</span>
            <input 
              required
              type="password" 
              onChange={(e) => setPassword(e.target.value)} 
              value={password} 
            />
          </label>
          {!isPending && <button className="btn" type="submit">Log in</button>}
          {isPending && <button className="btn" disabled>Loading</button>}
          {error && <div className="error">{error}</div>}
          <p onClick={() => setIsResetMode(true)} className="toggle-mode">
            Forgot password?
          </p>
          <p>
            Don't have an account? you can <Link to="/signup">create one</Link> for free.
          </p>
          <span>
            If you're having trouble accessing your account, please e-mail: contact@neuromodec.org
          </span>
        </form>
      )}
    </div>
  )
}
