import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { useAuthContext } from './hooks/useAuthContext'

import './App.css'

import Feed from './pages/feed/Feed' 
import Widgets from './components/Widgets' 
import Event from './pages/event/Event' 
import Login from './pages/login/Login' 
import Signup from './pages/signup/Signup'
import Sidebar from './components/Sidebar'
import Profile from './pages/profile/Profile'
import EventCalendar from './pages/EventCalendar/EventCalendar'
import PostSummary from './components/PostSummary'
import AddPublication from './components/AddPublication'
import Applicationpending from './pages/applicationpending/Applicationpending'
import Resources from './pages/resources/Resources'
import Watch from './pages/watch/Watch'
import Sessionpage from './pages/sessionPage/Sessionpage'
import SessionList from './pages/sessionlist/SessionList'
import Onboard from './pages/onboard/Onboard'




function App() {
  const { authIsReady, user } = useAuthContext()

  return (
    <div className="App">
      {authIsReady && (
        <BrowserRouter>
            <Switch>
              <Route exact path="/">
                {!user && <Redirect to="/login" />}
                {user && <Sidebar />}
                {user && <Feed />}
                {user && <Widgets/>}
              </Route>
              <Route exact path="/addPublication">
                {!user && <Redirect to="/login" />}
                {user && <Sidebar />}
                {user && <AddPublication />}
                {user && <Widgets/>}
              </Route>
              <Route path="/events/:id">
                <Event />
              </Route>
              <Route path="/events">
              <EventCalendar />
              </Route>
              <Route path="/profile/:id">
                {!user && <Redirect to="/login" />}
                {user && <Sidebar />}
                {user && <Profile />}
                {user && <Widgets />}
              </Route>
              <Route path="/posts/:id">
                {!user && <Redirect to="/login" />}
                {user && <Sidebar />}
                {user && <PostSummary />}
                {user && <Widgets/>}
              </Route>
              <Route path="/login">
                {user && <Redirect to="/" /> }
                {!user && <Login /> }
              </Route>
              <Route path="/signup">
                {user && <Redirect to="/" /> }
                {!user && <Signup /> }
              </Route>
              <Route path="/watch/:talkId">
                {!user && <SessionList /> }
                {user && <Watch /> }
              </Route>
              <Route path="/session/:id">
              {!user && <SessionList /> }
              {user && <Watch /> }
              </Route>
              <Route path="/2024-nyc-neuromodulation/sessions">
                <SessionList />
              </Route>
              <Route path="/resources">
                 {!user && <Redirect to="/" /> }
                 {user && <Sidebar />}
                 {user && <Resources /> }
                 {user && <Widgets/>}
              </Route>
            </Switch>
            {/* <Route path="/addsession">
                {user && <Redirect to="/" /> }
                {!user && <AddSession /> }
              </Route> */}
            <Route path="/application-pending">
                {user && <Applicationpending /> }
              </Route>

              <Route path="/onboard">
                {user && <Onboard /> }
              </Route> 
        
        </BrowserRouter>
      )}
    </div>
  );
}

export default App

