import './EventRegister.css'
import { useState, useEffect } from "react"
import { timestamp } from '../firebase/config'
import { useAuthContext } from '../hooks/useAuthContext'
import { useFirestore } from '../hooks/useFirestore'
import firebase from "firebase/app";
import Alert from '@mui/material/Alert';
import "firebase/firestore";
import { useHistory } from "react-router-dom";
import ConferenceFtBanner from '../assets/nycn_ft_widget.webp'


export default function EventRegister({event}) {
  const history = useHistory(); 

  const goToSignUp = () => { 
    history.push("/signup"); 
  } 
  
const [newAttendee, setNewAttendee] = useState('')
const { updateDocument, response } = useFirestore('events')
const { user } = useAuthContext()
const [attendeeFirstName, setAttendeeFirstName] = useState('')
const [attendeeLastName, setAttendeeLastName] = useState('')
const [attendeeAffiliation, setAttendeeAffiliation] = useState('')
const [userIsAttending, setUserIsAttending] = useState(false)


if (user) {

const db = firebase.firestore();

var docRef = db.collection("users").doc(user.uid);

docRef.get().then((doc) => {
   
  if (doc.exists) {
      var aUser = doc.data();
      setAttendeeFirstName(aUser.first_name)
      setAttendeeLastName(aUser.last_name)
      setAttendeeAffiliation(aUser.affiliation)
  
  } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
  }
 }).catch((error) => {
  console.log("Error getting document:", error);
 });

} else {}

 useEffect(() => {

const eventattendees = event.attendees

 eventattendees.map(eventattendee => {
 if(user) {
  if (user.uid === eventattendee.attendee_id) {
    setUserIsAttending(true)
 }
 }
  })
})

 const handleClick = async (e) => {

  const randomId = user.displayName
  const idLeft = randomId.slice(2,4)
  const idRight = randomId.slice(3,5)
  
  const createRandomId = `${idLeft}-${Math.random()}-${idRight}`
  
  
      e.preventDefault()
  
      const attendeeToAdd = {
          photoURL: user.photoURL,
          createdAt: timestamp.fromDate(new Date()),
          id: createRandomId,
          attendee_id:user.uid,
          first_name:attendeeFirstName,
          last_name:attendeeLastName,
          affiliation:attendeeAffiliation
      }
  
      await updateDocument(event.id, {   
          attendees: [...event.attendees, attendeeToAdd]
          
      })
    
  }

  return (
   
    <div className="eventRegistration-wrapper">
      <div className="eventRegistration-details">
      <h3>Registration</h3>
      <p>Registration to this event is <b>Free</b></p>
      <ul>
      {!user && !event.past_event && !event.canceled && <li><button className="event-btn" onClick={goToSignUp}>Register</button></li>}
      {!user && event.past_event && <li><button className="event-btn-success" disabled color="success" >Completed</button></li>}
      {user && event.past_event && <li><button className="event-btn-success" disabled color="success" >Completed</button></li>}
      {!user && event.canceled && <li><button className="event-btn" disabled color="success" >Registration closed</button></li>}
      {user && event.canceled && <li><button className="event-btn" disabled color="success" >Registration closed</button></li>}
      
      {user && !userIsAttending && !event.past_event && !event.canceled && <li><button className="event-btn" onClick={handleClick} >Register</button></li>}
       {userIsAttending && !event.past_event && <li><button className="event-btn-success" color="success" >You're attending!</button></li>}
      </ul>
      </div>
      {/* <div className="sponsorWid-item">
     <a target="_blank" href="https://bit.ly/47yDCnW">
   <img src={ConferenceFtBanner} alt="" className="sponsor-img" />
     </a>
   </div>  */}
    </div>
  
 
    
  )
}
