import { Link, useHistory } from 'react-router-dom'; // Use `useHistory` to programmatically navigate
import { useLogout } from '../hooks/useLogout';
import { useAuthContext } from '../hooks/useAuthContext';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
// Import Material UI Icons


// styles & images
import './Navbar.css';
import NeuromodecLogo from '../assets/logo-neuro-connect.svg';

export default function Navbar() {
  const { logout, isPending } = useLogout();
  const { user } = useAuthContext();
  const history = useHistory(); // Initialize the useHistory hook

  // Function to handle going back to the last page
  const handleGoBack = () => {
    history.goBack(); // Go back to the previous page in the browser history
  };

  return (
    <nav className="navbar">
      <ul>
        {!user && (
          <>
            <li className="logo">
              <img src={NeuromodecLogo} alt="Neuromodec logo" />
            </li>
            {/* <li><Link to="/resources"></Link></li> */}
            <li><Link to="/login">Login</Link></li>
            <li><Link to="/signup">Access On Demand content</Link></li>
          </>
        )}

        {user && (
          <>
            <li className="goBackBtn">
              <button onClick={handleGoBack} className="back-btn">
                <ArrowBackOutlinedIcon fontSize="medium" />
              </button>
            </li>
            {/* 
              <li>
                {!isPending && <button className="btn" onClick={logout}>Logout</button>}
                {isPending && <button className="btn" disabled>Logging out...</button>}
              </li> 
            */}
          </>
        )}
      </ul>
    </nav>
  );
}