import { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useSignup } from '../../hooks/useSignup'
import NeuromodecLogo from '../../assets/logo.svg'
import { useAuthContext } from '../../hooks/useAuthContext'
import firebase from "firebase/app";
import Select from 'react-select'
import Alert from '@mui/material/Alert';
import { useCollection } from '../../hooks/useCollection'


// styles
import './Applicationpending.css'

export default function Applicationpending() {

const { user } = useAuthContext()
const [userisverified, setUserisverified] = useState(false)
const { documents, error } = useCollection('users')


  const db = firebase.firestore();

   var docRef = db.collection("users").doc(user.uid);

   docRef.get().then((doc) => {
    if (doc.exists) {
        var uuser = doc.data();
       
        if (uuser.isverified) {
          setUserisverified(true)
        } else {
         
        }
         
    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
    }
}).catch((error) => {
    console.log("Error getting document:", error);
});

return (

    <div className="app-pending-wrapper">

  {/* {documents && documents.map(doc => (
     
        <p>{doc.email}</p>
      
))}    */}
        {/* {userisverified && <Redirect to="/"/>}
          <div className="logo">
              <img src={NeuromodecLogo} alt="Neuromodec Connect logo" />
            </div>
        <div className="pending-msg">
        <Alert severity="success">Thank you for applying to Neuromodec Connect. We've received your application and it is now being reviewed. You'll receive an e-mail confirmation once your application is approved. If you have any questions please contact us at contact@neuromodec.com</Alert>
        </div> */}
    </div>
)
}