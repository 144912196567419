import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/analytics'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  // measurementId: "G-3J8SXBWZH4"
};
  // init firebase

  firebase.initializeApp(firebaseConfig)

  // init services

  const projectFirestore = firebase.firestore()
  const projectAuth = firebase.auth()
  const projectStorage = firebase.storage()
  const projectAnalytics = firebase.analytics();

  // timestamp
  const timestamp = firebase.firestore.Timestamp
 const timestampDate = firebase.firestore.Timestamp.now().toDate().toString()
  
  export { projectFirestore, projectAuth, projectStorage, projectAnalytics, timestamp, timestampDate}
