import { useState, useEffect } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext'; // Hook for accessing current user
import firebase from 'firebase/app';
import Alert from '@mui/material/Alert';
import 'firebase/firestore';
import './Onboard.css';
import { useHistory } from 'react-router-dom'; // For navigation

export default function Onboard() {
  const { user } = useAuthContext(); // Get the logged-in user
  const [attendedConference, setAttendedConference] = useState(null); // Did the user attend?
  const [ticketNumber, setTicketNumber] = useState(''); // Ticket number input
  const [isSubscribed, setIsSubscribed] = useState(null); // Subscription status
  const [error, setError] = useState(''); // Error message
  const [loading, setLoading] = useState(false); // Loading status
  const [validTickets, setValidTickets] = useState([]); // Array of valid ticket numbers
  const history = useHistory(); // For redirecting the user to /resources

  // Fetch valid ticket numbers from Firestore when the component mounts
  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const ticketsSnapshot = await firebase.firestore().collection('tickets').get();
        const tickets = ticketsSnapshot.docs.map((doc) => doc.data().ticket_number); // Assuming the ticket number field is 'ticket_number'
        setValidTickets(tickets);
      } catch (error) {
        console.error('Error fetching tickets:', error);
      }
    };

    // Check if user is subscribed
    const checkSubscription = async () => {
      if (user) {
        const userDoc = await firebase.firestore().collection('users').doc(user.uid).get();
        if (userDoc.exists) {
          setIsSubscribed(userDoc.data().subscribed);
        }
      }
    };

    fetchTickets();
    checkSubscription();
  }, [user]);

  const handleTicketSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      // Fetch the ticket from Firestore
      const ticketDoc = await firebase.firestore().collection('tickets').where('ticket_number', '==', ticketNumber).get();

      if (!ticketDoc.empty) {
        const ticket = ticketDoc.docs[0];
        const ticketData = ticket.data();

        // Check if the ticket has already been claimed
        if (ticketData.claimed_by) {
          setError('This ticket has already been used. Please contact support if you believe this is an error.');
        } else {
          // Update the user object with subscribed: true and nycn_attendee: true, and mark the ticket as claimed
          await firebase.firestore().collection('users').doc(user.uid).update({
            subscribed: true,
            nycn_attendee: true, // Mark them as having attended the conference
          });

          // Mark the ticket as claimed by the current user
          await ticket.ref.update({
            claimed_by: user.uid,
          });

          setIsSubscribed(true); // Mark the user as subscribed
        }
      } else {
        setError('Invalid ticket number. Please contact support.');
      }
    } catch (err) {
      console.error('Error updating user subscription:', err);
      setError('There was an error verifying your ticket. Please try again. If you continue to see this message, please email us at contact@neuromodec.com');
    }

    setLoading(false);
  };

  // Handle if the user didn't attend the conference
  const handleNotAttending = async () => {
    try {
      // Update the user object with nycn_attendee: false
      await firebase.firestore().collection('users').doc(user.uid).update({
        nycn_attendee: false, // Mark them as not having attended
      });
    //   setError('You can subscribe to access the conference content.');
    } catch (err) {
      console.error('Error updating user info:', err);
      setError('There was an error processing your response. Please try again.');
    }
  };

  return (
    <div className="onboard-container">
      <h1>Neuromodec Connect account verification</h1>

      {/* If user is already subscribed */}
      {isSubscribed === true && (
        <div className="already-subscribed">
          <div className="verified-account">
          <Alert severity="success">Your account is verified. You have full access to on-demand content.</Alert>
          </div>
          <button onClick={() => history.push('/resources')} className="browse-resources-btn">
            Browse Resources
          </button>
        </div>
      )}

      {/* Step 1: Ask if the user attended, only show if they are not subscribed */}
      {isSubscribed !== true && attendedConference === null && (
        <div>
          <p>Did you attend the 2024 NYC Neuromodulation Conference?</p>
          <button onClick={() => setAttendedConference(true)}>Yes</button>
          <button onClick={() => { setAttendedConference(false); handleNotAttending(); }}>No</button>
        </div>
      )}

      {/* Step 2: Ask for the ticket number if attended */}
      {attendedConference === true && !isSubscribed && (
        <div>
          <form onSubmit={handleTicketSubmit}>
            <label htmlFor="ticket-number">Please enter your ticket number:</label>
            <input
              type="text"
              id="ticket-number"
              value={ticketNumber}
              onChange={(e) => setTicketNumber(e.target.value)}
              required
            />
                  {/* Show error message if the ticket is invalid */}
      {error && <p className="error">{error}</p>}
            <button type="submit" disabled={loading}>
              {loading ? 'Verifying...' : 'Submit'}
            </button>
          </form>

          {/* New Section: Where can I find my ticket number? */}
          <div className="ticket-info-section">
            <h3>Where can I find my order number?</h3>
            <div className="ticket-info-content">
              <p>
                Your order number can be found in your eventbrite ticket in the email sent to you when you registered for the
                conference.
              </p>
              {/* <img src="path-to-your-ticket-image.jpg" alt="Sample Ticket" className="ticket-info-image" /> */}
            </div>
            <div className="pricing-container">
            <div className="pricing-box">
              <div className="price">
                <h3><s>495 USD</s> Free</h3>
                <p>Gain access to on-demand content until June 30th, 2026</p>
              </div>
              <ul className="pricing-features">
                <li><span className="checkmark">✔</span> Full access to a select number of the 2024 NYC Neuromodulation recorded sessions</li>
                <li><span className="checkmark">✔</span> Access to exclusive resources</li>
                <li><span className="checkmark">✔</span> Access to recordings from the 2024 Neuromodec Webinar Series</li>
                <li><span className="checkmark">✔</span> Access to recordings of upcoming Neuromodec Webinars</li>
              </ul>
            </div>
          </div>
          </div>
        </div>
      )}

      {/* Show subscription confirmation */}
      {/* {isSubscribed && attendedConference && <p>You are successfully subscribed! Thank you!</p>} */}



      {/* Step 3: If they didn’t attend the conference */}
      {attendedConference === false && (
        <>
          <p>
            It seems like you didn’t attend the 2024 NYC Neuromodulation Conference. Please purchase an On Demand Ticket to access the content. <a href="https://buy.stripe.com/3cscOlgnPaS6gi49AA">Click here to purchase</a>.
          </p>

          {/* Pricing Section */}
          <div className="pricing-container">
            <h2>On Demand Access</h2>
            <div className="pricing-box">
              <div className="price">
                <h3>495 USD</h3>
                <p>One time Fee. Gain access to on-demand content until June 30th, 2026</p>
              </div>
              <ul className="pricing-features">
                <li><span className="checkmark">✔</span> Full access to a select number of the 2024 NYC Neuromodulation recorded sessions</li>
                <li><span className="checkmark">✔</span> Access to exclusive resources</li>
                <li><span className="checkmark">✔</span> Access to recordings from the 2024 Neuromodec Webinar Series</li>
                <li><span className="checkmark">✔</span> Access to recordings of upcoming Neuromodec Webinars</li>
              </ul>
              <a href="https://buy.stripe.com/3cscOlgnPaS6gi49AA">
                <button className="subscribe-btn">Purchase</button>
              </a>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
