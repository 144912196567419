import { NavLink } from "react-router-dom"
import { useAuthContext } from '../hooks/useAuthContext'
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import NeuromodecLogo from '../assets/logo.svg'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';



// components
import Avatar from "./Avatar"

// styles & images
import "./Sidebar.css"
import DashboardIcon from '../assets/dashboard_icon.svg'
import AddIcon from '../assets/add_icon.svg'

export default function Sidebar() {
  const { user } = useAuthContext()

  return (
    <div className="sidebar">
      <div className="sidebar-content"> 

      <div className="logo">
              <img src={NeuromodecLogo} alt="Neuromodec logo" />
      </div>

        <nav className="links">
          <ul>
            <li>
              <NavLink exact to="/">
                <HomeOutlinedIcon fontSize="large"/>
                <span>Feed</span>
              </NavLink>
            </li>
            {/* <li>
              <NavLink exact to="/communities">
              <GroupWorkOutlinedIcon fontSize="large" />
                <span>Community</span>
              </NavLink>
            </li> */}
            {/* <li>
              <NavLink exact to="/notifications">
              <NotificationsNoneIcon fontSize="large" />
                <span>Notifications</span>
              </NavLink>
            </li> */}
            <li className="new-item">
          <NavLink exact to="/resources">
            <PlayCircleOutlineIcon fontSize="large" />
            <span>
              On-Demand <span className="badge">New</span>
            </span>
          </NavLink>
        </li>

            <li>
              <NavLink exact to="/events">
              <EventNoteOutlinedIcon fontSize="large" />
                <span>Upcoming Webinars</span>
              </NavLink>
            </li>
            <li>
              <NavLink exact to={`/profile/${user.displayName}`}>
              <Person2OutlinedIcon fontSize="large" />
                <span>Profile</span>
              </NavLink>
            </li>

            

            </ul>
        </nav>
      </div>
    </div>
  )
}