import './EventWidget.css'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useCollection } from '../hooks/useCollection'

export default function EventWidget() {
  // Upcoming Events section: fetch events from Firestore
  const { documents, error } = useCollection('events')

  // On demand - Featured section: sample array of talk objects
  const talksArray = [
    {
      id: 1,
      talkTitle: 'Space, frequency, and phase: Personalized tACS',
      speaker: 'Robert Reinhart, PhD',
      degree: 'Boston University',
      url: '/watch/B1cWvHvN9mevsJkgMAJp'
    },
    {
      id: 2,
      talkTitle: 'Direct Brain Stimulation Modulates Encoding States and Memory Performance in Humans',
      speaker: 'Youssef Ezzyat, PhD',
      degree: 'Wesleyan University',
      url: '/watch/RzORSLmGUtvqRLLKlZ4n'
    },
    {
      id: 3,
      talkTitle: 'Sensing-enabled and Multi-target deep brain stimulation for epilepsy',
      speaker: 'Robert Gross, MD',
      degree: 'Emory University',
      url: '/watch/9QBBK9LqgwfLQq2KR3Vy'
    },
    {
      id: 4,
      talkTitle: 'Miniature battery-free implanted neuro-stimulators',
      speaker: 'Jacob Robinson, PhD',
      degree: 'Motif',
      url: '/watch/HUGWzYODlmpJ1TAGNW2L'
    },
    {
      id: 5,
      talkTitle: 'Endovascular brain-computer interface',
      speaker: 'Riki Banerjee, PhD',
      degree: 'Synchron',
      url: '/watch/LusKp7JrT0zYuMVrKbU0'
    },
    {
      id: 6,
      talkTitle: 'Wearable Disposable Electrotherapy',
      speaker: 'Mohamad FallahRad, PhD',
      degree: 'CCNY',
      url: '/watch/emZoLWgXNB2JBgYs4rY7'
    },
  
    {
      id: 7,
      talkTitle: 'Long-lasting memory improvements in older adults using repetitive HD-tACS',
      speaker: 'Robert Reinhart, PhD',
      degree: 'Boston University',
      url: '/watch/BOx9hbITBwr7xPfHZLTu'
    }
  ];

  const [selectedTalks, setSelectedTalks] = useState([]);

  useEffect(() => {
    // Shuffle the talks array and pick 5 random talks
    const shuffled = [...talksArray].sort(() => 0.5 - Math.random());
    setSelectedTalks(shuffled.slice(0, 5));
  }, []);

  return (
    <div>

        {/* On demand – Featured Section */}
        <div className="eventWid-wrap">
        <h3>On demand - Featured</h3>
        {selectedTalks.map(talk => (
          <div key={talk.id} className="eventWid-item">
            <h4>{talk.talkTitle}</h4>
            <p>{talk.speaker} - {talk.degree}</p>
            <Link to={talk.url}>
              <button className="btn-watch-featured">Watch Now</button>
            </Link>
          </div>
        ))}
      </div>

      {/* Upcoming Events Section */}
      <div className="eventWid-wrap">
      <h3>Upcoming Events</h3>
      <ul>
        {error && <p className="error">{error}</p>}
        {documents && documents
          .filter(doc => !doc.past_event)
          .map(doc => (
            <li key={doc.id}>
              <Link to={`/events/${doc.id}`}>
                <div className="eventWid-item">
                  <span>{doc.type}</span>
                  <h4>{doc.name}</h4>
                  <p>{doc.date.toDate().toDateString()}</p>
                </div>
              </Link>
            </li>
          ))}
      </ul>
      </div>
    </div>
  );
}
