import React, { useState } from 'react';
import { useCollection } from '../../hooks/useCollection';
import { Link } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import './EventCalendar.css';

export default function EventCalendar() {
  // Fetch events from Firestore (sorted by date descending originally)
  const { documents, error } = useCollection('events', null, ["date", "desc"]);
  
  // Toggle between upcoming and on-demand events
  const [selectedCategory, setSelectedCategory] = useState("upcoming");

  const handleCategoryChange = (event, newCategory) => {
    if (newCategory !== null) {
      setSelectedCategory(newCategory);
    }
  };

  // Filter events based on the selected category:
  // Upcoming: events where past_event is false
  // On-Demand: events where past_event is true
  const filteredDocs = documents
    ? documents.filter(doc => {
        if (selectedCategory === "upcoming") {
          return !doc.past_event;
        } else if (selectedCategory === "on-demand") {
          return doc.past_event;
        }
        return true;
      })
    : [];

  // Sort events:
  // Upcoming events sorted ascending (closest date first)
  // On-Demand events sorted descending (most recent first)
  const sortedDocs = filteredDocs.slice().sort((a, b) => {
    const dateA = a.date.toDate();
    const dateB = b.date.toDate();
    if (selectedCategory === "upcoming") {
      return dateA - dateB;
    } else {
      return dateB - dateA;
    }
  });

  return (
    <div className="eventCalendarWrapper">
      <Navbar />
      <div className="eventCalendarHeader">
        <h1>Neuromodec Webinars</h1>
      </div>
      
      {/* Toggle Switch for Event Categories */}
      <div className="eventCalendarToggle">
        <ToggleButtonGroup
          value={selectedCategory}
          exclusive
          onChange={handleCategoryChange}
          aria-label="Event Category"
        >
          <ToggleButton value="upcoming" aria-label="Upcoming events">
            Upcoming
          </ToggleButton>
          <ToggleButton value="on-demand" aria-label="On Demand events">
            On-Demand
          </ToggleButton>
        </ToggleButtonGroup>
      </div>

      <div className="eventCalendarBody">
        {error && <p className="error">{error}</p>}
        <ul>
          {sortedDocs.map(doc => (
            <li key={doc.id}>
              <Link to={`/events/${doc.id}`}>
                <div className="eventCard-wrapper">
                  <div className="eventCard-img">
                    <img src={doc.img_url} alt={doc.name} />
                  </div>
                  <div className="eventCard-details">
                    {/* Uncomment if needed: <span>{doc.type}</span> */}
                    <h3>{doc.name}</h3>
                    <p>
                      {doc.day === '' 
                        ? "Date: TBD" 
                        : `${doc.day}, ${doc.month} ${doc.day_digit}, ${doc.year} | ${doc.start_time} - ${doc.end_time} (ET)`}
                    </p>
                    <p>{doc.type}</p>
                  </div>
                  {doc.past_event && <button className="btn">Watch Webinar</button>}
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
