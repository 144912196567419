import './Resources.css';
import React, { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import { HashLink } from 'react-router-hash-link';
import firebase from 'firebase/app';
import 'firebase/firestore'; // Import Firestore
import { useAuthContext } from '../../hooks/useAuthContext';
import { useHistory } from 'react-router-dom'; // For navigation
import CheckIcon from '@mui/icons-material/Check'; // Custom hook to get the current user

export default function Resources() {
  const { user } = useAuthContext(); // Access the logged-in user
  const [isSubscribed, setIsSubscribed] = useState(null); // Subscription status
  const [loading, setLoading] = useState(true); // Loading state
  const history = useHistory();

  // Fetch user's subscription status from Firestore
  useEffect(() => {
    const fetchUserSubscription = async () => {
      if (user) {
        const userDoc = await firebase.firestore().collection('users').doc(user.uid).get();
        if (userDoc.exists) {
          setIsSubscribed(userDoc.data().subscribed);
        }
      }
      setLoading(false);
    };

    fetchUserSubscription();
  }, [user]);

  return (
    <div className="feed">
      <div className="feedHeader">
        <h2>On Demand Content & Resources</h2>
      </div>

      {!user || !isSubscribed && 
      <Alert severity="info">
        Neuromodec Connect on-demand content is available at no cost to all conference attendees. Non-attendees can purchase access to the complete on-demand library by clicking <a href="https://buy.stripe.com/3cscOlgnPaS6gi49AA">here.</a>
      </Alert> }
      {/* <div className="resourcesheader"></div> */}
      <div className="resources-details">
        <p>
         Below you can access recorded sessions from the 2024 NYC Neuromodulation Conference and recorded webinars from the 2024 Neuromodec Webinar series. Whether you missed a session or want to revisit key insights, everything is available on-demand for your convenience.
        </p>
        <p>Check back regularly for updates and new content.</p>
      </div>

      {/* Subscription Verification Section */}
      <div className="subscription-status">
        {loading ? (
          <p>Loading...</p>
        ) : isSubscribed ? (
          <Alert severity="success">Your account is verified, you have full access to on-demand content.</Alert>
        ) : (
          <Alert severity="error" action={<button onClick={() => history.push('/onboard')} className="verify-btn">Verify</button>}>Please verify your account so you can access on-demand content. </Alert>
        )}
      </div>

      <div className="resources-body">
        <HashLink style={{ textDecoration: 'none' }} smooth to="/2024-nyc-neuromodulation/sessions">
          <div className="resource-item-1">
            <div className="resource-item-details-1">
              <h3>2024 NYC Neuromodulation Conference</h3>
              <p>July 31 - August 4, 2024</p>
              <button className="btn">Browse Content</button>
            </div>
          </div>
        </HashLink>

        {/* <div className="resource-item-2">
          <div className="resource-item-details-2">
            <h3>NYC Clinical & Research TMS Course</h3>
            <p>August 4, 2024</p>
            <Alert severity="info">Available September 20</Alert>
          </div>
        </div> */}
      <HashLink style={{ textDecoration: 'none' }} smooth to="/events">
        <div className="resource-item-3">
          <div className="resource-item-details-3">
            <h3>2024 Neuromodec Webinar Series</h3>
            <p>November 2023 - June 2024</p>
            <button className="btn">Watch Webinars</button>
          </div>
        </div>
        </HashLink>
      </div>
    </div>
  );
}
