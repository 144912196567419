import './Watch.css';
import { useState, useEffect } from 'react';
import Navbar from '../../components/Navbar';
import React from 'react';
import { useParams } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore'; // Import Firestore
import 'firebase/auth'; // Import Firebase Auth

// Import Material UI Icons
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LockIcon from '@mui/icons-material/Lock';

const timestamp = firebase.firestore.Timestamp;

export default function Watch() {
  const { talkId } = useParams();
  const [talk, setTalk] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [newReply, setNewReply] = useState('');
  const [loading, setLoading] = useState(true);
  const [sessionId, setSessionId] = useState(null);
  const [replyTo, setReplyTo] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [currentVideoUrl, setCurrentVideoUrl] = useState(null); // Track the current video URL
  const [slideshowUrl, setSlideshowUrl] = useState(''); // URL for slideshow version

  const currentUser = firebase.auth().currentUser;

  // Fetch the logged-in user's details from Firestore
  useEffect(() => {
    const fetchUserDetails = async () => {
      if (currentUser) {
        const userDoc = await firebase.firestore().collection('users').doc(currentUser.uid).get();
        if (userDoc.exists) {
          setUserDetails(userDoc.data());
        }
      }
    };

    fetchUserDetails();
  }, [currentUser]);

  // Fetch talk data and comments from Firestore
  useEffect(() => {
    const fetchTalkData = async () => {
      try {
        const sessionsCollection = firebase.firestore().collection('sessions');
        let talkData = null;
        let foundSessionId = null;

        const sessionSnapshot = await sessionsCollection.get();
        for (const sessionDoc of sessionSnapshot.docs) {
          const talksCollection = sessionDoc.ref.collection('talks');
          const talkDoc = await talksCollection.doc(talkId).get();

          if (talkDoc.exists) {
            talkData = talkDoc.data();
            foundSessionId = sessionDoc.id;
            break;
          }
        }

        if (talkData) {
          setTalk(talkData);
          setSessionId(foundSessionId);
          setCurrentVideoUrl(talkData.talk_url); // Initialize the video URL
          setSlideshowUrl(talkData.slideshow_url); // Initialize the slideshow URL

          const discussionSnapshot = await firebase
            .firestore()
            .collection('sessions')
            .doc(foundSessionId)
            .collection('talks')
            .doc(talkId)
            .collection('discussion')
            .get();

          const fetchedComments = discussionSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setComments(fetchedComments);
        } else {
          console.error('Talk not found!');
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching data: ', error);
        setLoading(false);
      }
    };

    fetchTalkData();
  }, [talkId]);

  // Function to handle slideshow thumbnail click
  const handleSlideshowClick = () => {
    setCurrentVideoUrl(slideshowUrl); // Update the video URL to the slideshow URL
  };

  // Function to handle locked state button click
  const handleLockAction = () => {
    if (!currentUser) {
      window.location.href = '/login';
    } else {
      window.location.href = '/onboard';
    }
  };

  // Handle adding a new comment
  const handleAddComment = async () => {
    if (!talkId || !sessionId) return;

    if (!currentUser || !userDetails) {
      alert('You need to be logged in to comment.');
      return;
    }

    const newCommentObj = {
      comment_text: newComment,
      like_count: 0,
      likes: [],
      replies: [],
      timestamp: timestamp.now(),
      user: {
        uid: currentUser.uid,
        first_name: userDetails.first_name,
        last_name: userDetails.last_name,
        email: currentUser.email,
      },
    };

    try {
      await firebase
        .firestore()
        .collection('sessions')
        .doc(sessionId)
        .collection('talks')
        .doc(talkId)
        .collection('discussion')
        .add(newCommentObj);

      const snapshot = await firebase
        .firestore()
        .collection('sessions')
        .doc(sessionId)
        .collection('talks')
        .doc(talkId)
        .collection('discussion')
        .get();

      const updatedComments = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setComments(updatedComments);
      setNewComment('');
    } catch (error) {
      console.error('Error adding comment: ', error);
    }
  };

  // Handle deleting a comment
  const handleDeleteComment = async (commentId) => {
    try {
      await firebase
        .firestore()
        .collection('sessions')
        .doc(sessionId)
        .collection('talks')
        .doc(talkId)
        .collection('discussion')
        .doc(commentId)
        .delete();

      const snapshot = await firebase
        .firestore()
        .collection('sessions')
        .doc(sessionId)
        .collection('talks')
        .doc(talkId)
        .collection('discussion')
        .get();

      const updatedComments = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setComments(updatedComments);
    } catch (error) {
      console.error('Error deleting comment: ', error);
    }
  };

  // Handle like/unlike functionality
  const handleLike = async (commentId, likes) => {
    const isLiked = likes.some((like) => like.uid === currentUser.uid);

    try {
      const commentRef = firebase
        .firestore()
        .collection('sessions')
        .doc(sessionId)
        .collection('talks')
        .doc(talkId)
        .collection('discussion')
        .doc(commentId);

      if (isLiked) {
        const likeToRemove = likes.find((like) => like.uid === currentUser.uid);
        await commentRef.update({
          likes: firebase.firestore.FieldValue.arrayRemove(likeToRemove),
          like_count: firebase.firestore.FieldValue.increment(-1),
        });
      } else {
        const likeEntry = {
          uid: currentUser.uid,
          timestamp: timestamp.now(),
        };
        await commentRef.update({
          likes: firebase.firestore.FieldValue.arrayUnion(likeEntry),
          like_count: firebase.firestore.FieldValue.increment(1),
        });
      }

      const snapshot = await firebase
        .firestore()
        .collection('sessions')
        .doc(sessionId)
        .collection('talks')
        .doc(talkId)
        .collection('discussion')
        .get();

      const updatedComments = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setComments(updatedComments);
    } catch (error) {
      console.error('Error updating like: ', error);
    }
  };

  // Handle replying to a comment
  const handleReply = (commentId, commentUser) => {
    setReplyTo({ commentId, displayName: `${commentUser.first_name} ${commentUser.last_name}` });
  };

  // Submit reply to a comment
  const handleAddReply = async () => {
    if (!newReply || !replyTo || !userDetails) return;

    const reply = {
      reply_text: newReply,
      timestamp: timestamp.now(),
      user: {
        uid: currentUser.uid,
        first_name: userDetails.first_name,
        last_name: userDetails.last_name,
        email: currentUser.email,
      },
    };

    try {
      const commentRef = firebase
        .firestore()
        .collection('sessions')
        .doc(sessionId)
        .collection('talks')
        .doc(talkId)
        .collection('discussion')
        .doc(replyTo.commentId);

      await commentRef.update({
        replies: firebase.firestore.FieldValue.arrayUnion(reply),
      });

      const snapshot = await firebase
        .firestore()
        .collection('sessions')
        .doc(sessionId)
        .collection('talks')
        .doc(talkId)
        .collection('discussion')
        .get();

      const updatedComments = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setComments(updatedComments);
      setNewReply('');
      setReplyTo(null);
    } catch (error) {
      console.error('Error adding reply: ', error);
    }
  };

  // Adjust textarea height dynamically
  const handleTextAreaChange = (event) => {
    const textarea = event.target;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
    setNewComment(event.target.value);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!talk) {
    return <div>An error has occurred. Try refreshing the page. If the issue persists, please email us at contact@neuromodec.com</div>;
  }

  // Determine if the current user is allowed to view content (signed in and subscribed)
  const isAllowed = currentUser && userDetails && userDetails.subscribed;

  return (
    <div className="watchContainer">
      <Navbar />
      <div className="watchHeader">
        <div className="watch-wrapper">
          <div className="watch-left">
            <h1>{talk.talk_title || 'Watch Talk'}</h1>

           <div className={`video-player ${!isAllowed ? 'no-padding' : ''}`}>
  {isAllowed ? (
    currentVideoUrl ? (
      <iframe
        src={currentVideoUrl}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        title="Talk Video"
      ></iframe>
    ) : (
      <p>No video available</p>
    )
  ) : (
    <div className="locked-video">
      <LockIcon style={{ fontSize: '48px', color: '#999' }} />
      <p>{!currentUser ? 'Sign in to view' : 'Verify your account to view'}</p>
      <button onClick={handleLockAction}>
        {!currentUser ? 'Sign in to view' : 'Verify your account to view'}
      </button>
    </div>
  )}
</div>


            {/* Hide the slideshow version if content is locked */}
            {/* {isAllowed && (
              <div className="slideshow-version">
                <h3>Watch Slideshow Version Instead</h3>
                <div className="slideshow-thumbnail" onClick={handleSlideshowClick}>
                  <img src="../src/assets/thumbnail_talk.png" alt="Slideshow Thumbnail" className="thumbnail" />
                </div>
              </div>
            )} */}

            <div className="lesson-text">
              <h3>Speaker</h3>
              <div className="attendee-card">
                <div className="attendee-img">
                  {/* <img src={talk.speaker.photo_url} alt="Host Profile picture" /> */}
                </div>
                <div className="attendee-info">
                  <h4>{talk.speaker.first_name} {talk.speaker.last_name}, {talk.speaker.degree}</h4>
                  <p>{talk.speaker.affiliation}</p>
                </div>
              </div>
            </div>
          </div>

          {/* Discussion section */}
          <div className="watch-right">
            <div className="chat-panel">
              <h4>Discussion</h4>
              <div className="comment-section">
                {comments.map((comment) => (
                  <div key={comment.id} className="comment">
                    <p>
                      <strong>{`${comment.user.first_name} ${comment.user.last_name}`}</strong> - {comment.comment_text}
                    </p>
                    <div className="comment-actions">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ThumbUpIcon
                          onClick={() => handleLike(comment.id, comment.likes)}
                          style={{
                            cursor: 'pointer',
                            color: comment.likes.some((like) => like.uid === currentUser.uid) ? 'blue' : 'gray',
                          }}
                        />
                        <span style={{ marginLeft: '5px' }}>{comment.like_count || 0}</span>
                      </div>
                      <button className="reply-button" onClick={() => handleReply(comment.id, comment.user)}>Reply</button>
                      {comment.user.uid === currentUser.uid && (
                        <DeleteOutlineIcon
                          onClick={() => handleDeleteComment(comment.id)}
                          style={{ cursor: 'pointer', color: 'red', marginLeft: '170px' }}
                        />
                      )}
                    </div>

                    {comment.replies && comment.replies.length > 0 && (
                      <div className="replies">
                        {comment.replies.map((reply, index) => (
                          <div key={index} className="reply">
                            <p>
                              <strong>{`${reply.user.first_name} ${reply.user.last_name}`}</strong> - {reply.reply_text}
                            </p>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>

              {replyTo && (
                <div className="replying-to">
                  Replying to <strong>{replyTo.displayName}</strong>
                </div>
              )}

              {isAllowed ? (
                <div className="add-comment">
                  <textarea
                    value={replyTo ? newReply : newComment}
                    onChange={replyTo ? (e) => setNewReply(e.target.value) : handleTextAreaChange}
                    placeholder={replyTo ? `Replying to ${replyTo.displayName}` : 'Type a comment...'}
                    rows="1"
                  ></textarea>
                  <button onClick={replyTo ? handleAddReply : handleAddComment}>Send</button>
                </div>
              ) : (
                <div className="locked-chat">
                  <LockIcon style={{ fontSize: '48px', color: '#999' }} />
                  <p>{!currentUser ? 'Sign in to join the conversation' : 'Verify your account to join the conversation'}</p>
                  <button onClick={handleLockAction}>
                    {!currentUser ? 'Sign in to join the conversation' : 'Verify your account to join the conversation'}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
