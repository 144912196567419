import './SessionList.css';
import React, { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth'; // Import Firebase Auth
import { useHistory } from 'react-router-dom'; // Import useHistory for navigation
import Navbar from '../../components/Navbar';

export default function SessionList() {
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null); // State to track if user is logged in
  const [isSubscribed, setIsSubscribed] = useState(false); // State to track if user is subscribed
  const history = useHistory(); // React Router hook for navigation

  useEffect(() => {
    // Check if the user is authenticated and fetch subscription status
    const unsubscribe = firebase.auth().onAuthStateChanged(async (currentUser) => {
      setUser(currentUser); // Set user if authenticated, null if not

      if (currentUser) {
        // Fetch user document to check subscription status
        const userDoc = await firebase.firestore().collection('users').doc(currentUser.uid).get();
        if (userDoc.exists) {
          setIsSubscribed(userDoc.data().subscribed || false); // Set subscription status
        }
      }
    });

    // Fetch sessions and talks from Firestore
    const fetchSessions = async () => {
      try {
        const sessionCollection = firebase.firestore().collection('sessions');
        const sessionSnapshot = await sessionCollection.get();
        const sessionList = await Promise.all(
          sessionSnapshot.docs.map(async (doc) => {
            const talksSnapshot = await doc.ref.collection('talks').get();
            const talks = talksSnapshot.docs.map((talkDoc) => ({
              id: talkDoc.id,
              ...talkDoc.data(),
            }));
            return {
              id: doc.id,
              ...doc.data(),
              talks, // Include talks under each session
            };
          })
        );
        setSessions(sessionList);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching sessions:', error);
        setLoading(false);
      }
    };

    fetchSessions();

    // Cleanup subscription to auth state listener when component unmounts
    return () => unsubscribe();
  }, []);

  // Handle navigation when a session is clicked
  const handleSessionClick = (sessionId) => {
    history.push(`/session/${sessionId}`);
  };

  const handleSignIn = () => {
    history.push('/login'); // Redirect to sign-in page if not logged in
  };

  const handleVerify = () => {
    history.push('/onboard'); // Redirect to the onboard page
  };

  if (loading) {
    return <div>Loading sessions...</div>;
  }

  return (
    <div className="sessionListWrapper">
      <Navbar />

      <div className="sessionListHeader"></div>

      <div className="resources-details">
        <p>
          The 2024 NYC Neuromodulation Conference provided attendees with insights into the most timely and important
          advances in neurotechnology / neuromodulation.
        </p>
        <p>Below you can access recorded sessions and on demand content from the conference.</p>
      </div>

      {!user || !isSubscribed &&

      <Alert severity="info">
        The 2024 NYC Neuromodulation Conference on-demand content is available at no cost to all conference attendees.
        Non-attendees can purchase post conference registration to access on-demand sessions, recorded talks, and other resources by clicking <a href="https://buy.stripe.com/3cscOlgnPaS6gi49AA">here.</a>
      </Alert>
        } 
        
      <div className="resources-body">
        {sessions.map((session) => (
          <div key={session.id} className="resource-item-1">
              {/* <div className="resource-item-details-1" onClick={() => handleSessionClick(session.id)}>  Deleted for now until all sessions are complete*/}
            <div className="resource-item-details-1">
              <h3>{session.session_title}</h3>
            </div>
            <div className="talks-list">
              {session.talks.map((talk) => (
                <div key={talk.id} className="talk-item">
                  <div className="talk-details">
                    <h4>{talk.talk_title}</h4>
                    <div className="speaker-info">
                      <div className="speaker-details">
                        <p>{`${talk.speaker.first_name} ${talk.speaker.last_name}, ${talk.speaker.degree} - ${talk.speaker.affiliation}`}</p>
                      
                        {user && !isSubscribed && (
                            <Alert severity="warning" className="verify-alert">
                               Verify your account to watch.
                             </Alert>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Show the "Verify" button and alert if the user is not subscribed */}
                  <div className="talk-action-column">
                    {user && !isSubscribed ? (
                      <>
                        <div className="talk-action">
                          <button className="action-btn" onClick={handleVerify}>
                            Verify
                          </button>
                        </div>
                      </>
                    ) : (
                      <div className="talk-action">
                        {talk.recording_availability ? (
                          user ? (
                            <button className="action-btn" onClick={() => history.push(`/watch/${talk.id}`)}>
                              Watch
                            </button>
                          ) : (
                            <button className="action-btn" onClick={handleSignIn}>
                              Sign in to access
                            </button>
                          )
                        ) : (
                          <button className="action-btn pending-btn" disabled>
                            Pending
                          </button>
                        )}
                      </div>
                    )}
                  </div>

                  {/* Add a horizontal rule to separate talks */}
                  <hr className="talk-divider" />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
