import { useEffect, useState } from 'react'

import './Feed.css'
import { timestamp, timestampDate } from '../../firebase/config'
import { useAuthContext } from '../../hooks/useAuthContext'
import Avatar from "../../components/Avatar"
import { useCollection } from '../../hooks/useCollection'
import { useFirestore } from '../../hooks/useFirestore'
import { useHistory } from 'react-router'
import firebase from "firebase/app";
import "firebase/firestore";
import PostList from '../../components/PostList'
import InfoIcon from '@mui/icons-material/Info';
import Alert from '@mui/material/Alert';
import { Link } from 'react-router-dom';
import EastIcon from '@mui/icons-material/East';
import { HashLink } from 'react-router-hash-link';
import FeedBanner from '../../assets/bg_feed.webp';


export default function Feed() {

  // Post field values 
  const [postText, setPostText] = useState('')
  const [postImage, setPostImage] = useState(null)
  const [postDate, setPostDate] = useState('')
  const [postImageError, setPostImageError] = useState(null)
  const [users, setUsers] = useState([])
  const { user } = useAuthContext()
  const { addDocument, response } = useFirestore('posts')
  const history = useHistory()
  const [userFirstName, setUserFirstName] = useState('')
  const [userLastName, setUserLasttName] = useState('')
  const [userPhotoUrl, setUserPhotoUrl] = useState('')
  const [likes, setLikes] = useState([])
  const { documents, error } = useCollection(
    'posts', null,
    ["createdAt", "desc"]
  )

  const db = firebase.firestore();

  var docRef = db.collection("users").doc(user.uid);

  docRef.get().then((doc) => {
    if (doc.exists) {
      var uuser = doc.data();
      setUserFirstName(uuser.first_name)
      setUserLasttName(uuser.last_name)
      setUserPhotoUrl(uuser.photoURL)
    } else {
      console.log("No such document!");
    }
  }).catch((error) => {
    console.log("Error getting document:", error);
  });

  const handleSubmit = async (e) => {
    e.preventDefault()

    const post = {
      post_content: postText,
      post_image_url: postImage,
      comments: [],
      likes: [],
      userFirstName,
      userLastName,
      userPhotoUrl,
      posted_by: user.uid,
      posted_on: timestampDate,
    }

    await addDocument(post)
    
    if (!response.error) {
      setPostText('')
    }
  }
  
  const handleFileChange = (e) => {
    setPostImage(null)
    let selected = e.target.files[0]
    console.log(selected)

    if (!selected) {
      return
    }
    if (!selected.type.includes('image')) {
      setPostImageError('Selected file must be an image')
      return
    }
    if (selected.size > 500000) {
      setPostImageError('Image file size must be less than 100kb')
      return
    }
    
    setPostImageError(null)
    setPostImage(selected)
    console.log('thumbnail updated')
  }
   
  return (
    <div className="feed">
      {/* New banner with an image */}
      <div className="ann-banner">
        <img src={FeedBanner} alt="Banner" className="banner-image" />
      </div>

      {/* New introductory section with two left buttons */}
      <div className="intro-section">
        <div className="intro-left">
          <div className="intro-text">
            Welcome to Neuromodec Connect, A private forum designed for professionals, researchers, and students in neuromodulation and neurotechnology. 
            Below you can access recorded sessions from the 2024 NYC Neuromodulation Conference and past Neuromodec webinars with more content coming soon.
            Use the feed below to share job openings and general neuromodulation news.
          </div>
          <div className="intro-buttons">
          <Link to="/resources"><button className="intro-button">Access on demand content</button></Link>
           
          </div>
        </div>
      </div>

      {/* Existing Alert */}
      <Alert severity="info">
        Neuromodec Connect is currently in Beta. Please let us know if you encounter any issues by contacting: contact@neuromodec.com
      </Alert>
      
      <div className="feedHeader">
        <h2>News & General Discussions</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="post-wrapper">
          <div className="post-input-box">
            <div className="post-img-wrap">
              <Avatar src={user.photoURL} className="avator"/>
            </div>
            <div className="post-text">
              <textarea
                required 
                type="email" 
                placeholder='Say something here... You can share information about upcoming events, a job posting, a new publication, news, etc..'
                onChange={(e) => setPostText(e.target.value)}
                value={postText}
              />
            </div>
          </div>
          <div className="post-footer">
            <ul className="post-actions">
              {/* File upload functionality has been commented out */}
            </ul>
            <div className="post-cta">
              <button className="btn">Post</button>
            </div>
          </div>
        </div>
      </form>

     {/* <div className="flev-wrapper"> */}

       {/* <div className="flev-thumbnail">
        </div> */}
         {/* <div className="flev-details">
        <span className="live-badge">LIVE</span>
        <h3>Digital Twins for Spinal Cord Stimulation</h3>
        <p>This Webinar will explore computational strategies to develop and apply digital twins for advancing SCS therapies.</p>
        <button className="btn" ><HashLink smooth to="/events/oIoadmBzd2yDfVtS9oOI#qa">See the Discussion / Q&A</HashLink></button>
        </div> 
       </div> */}

      {error && <p className="error">{error}</p>}
      {documents && <PostList posts={documents} />}
     
      <Link to="/events"><button className="btn-mobile">Browse Webinars</button></Link>
    </div>
  )
}
