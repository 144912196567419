import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSignup } from '../../hooks/useSignup'
import NeuromodecLogo from '../../assets/logo.svg'
import Select from 'react-select'
import FeatureBanner from '../../assets/signup-bg-ft.webp'

// styles
import './Signup.css'

export default function Signup() {
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [password, setPassword] = useState('')
  const [thumbnail, setThumbnail] = useState(null)
  const [thumbnailError, setThumbnailError] = useState(null)
  const [affiliation, setAffiliation] = useState('')
  const [degree, setDegree] = useState('')
  const { signup, isPending, error } = useSignup()
  const [thumbnailErr, setThumbnailErr] = useState(true)
  
  const handleSubmit = (e) => {
    e.preventDefault()
    const createDisplayName = `${firstName}${lastName}${Math.floor(Math.random() * 100)}${Math.floor(Math.random() * 10)}`
    const displayName = createDisplayName
    signup(firstName, lastName, email, password, displayName, affiliation)
  }

  return (
    <div className="mainWrapper">
      <div className="leftContainer">
        <div className="logo">
          <img src={NeuromodecLogo} alt="Neuromodec logo" />
        </div>
        <form onSubmit={handleSubmit} className="auth-form">
          <h1>Neuromodec Connect</h1>
          <h4>Access on demand Content from previous Neuromodec Events and attend upcoming Webinars.</h4>
          <p>For verification purposes, please make sure you use your university or company e-mail.</p>
          <p>If you already have a Neuromodec Connect account, <b><Link to="/login">Sign in</Link></b> instead.</p>
          <label>
            <span>First Name*</span>
            <input
              required 
              type="text" 
              onChange={(e) => setFirstName(e.target.value)} 
              value={firstName}
            />
          </label>
          <label>
            <span>Last Name*</span>
            <input
              required 
              type="text" 
              onChange={(e) => setLastName(e.target.value)} 
              value={lastName}
            />
          </label>
          <label>
            <span>Email*</span>
            <input
              required 
              type="email" 
              onChange={(e) => setEmail(e.target.value)} 
              value={email}
            />
          </label>
          <label>
            <span>Password*</span>
            <input
              required
              type="password" 
              onChange={(e) => setPassword(e.target.value)} 
              value={password}
            />
          </label>
          <label>
            <span>Company/Institution*</span>
            <input
              required
              type="text" 
              onChange={(e) => setAffiliation(e.target.value)} 
              value={affiliation}
            />
          </label>
          {!isPending && <button className="btn">Sign up</button>}
          {isPending && <button className="btn" disabled>loading</button>}
          {error && <div className="error">{error}</div>}
        </form>
      </div>

      <div className="rightContainer">
        <div className="features">
          <h2> What is Neuromodec Connect?</h2>
          <p>
            Neuromodec Connect is a free, private forum designed for professionals,
            researchers, and students in neuromodulation and neurotechnology. 
          </p>
          <h2>Why join Neuromodec Connect?</h2>
          <p>
          Unlock exclusive access to recorded sessions from the 2024 NYC Neuromodulation Conference, explore a library of on-demand content from past Neuromodec events, and stay informed with upcoming webinars.
          </p>
          {/* <div className="feature-image">
            <img src={FeatureBanner} alt="Platform Features" />
          </div> */}
          <p>
            Join Neuromodec Connect today to connect, share, and grow your knowledge in the field.
          </p>
        </div>
      </div>
    </div>
  )
}
